import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/layout';
import SignupForm from './components/signup-form';
import LoginForm from './components/login-form';
import FreightRegistration from './components/freight-registration-form';
import FilterableCargo from './components/filterable-cargo';
import { ProvideAuth } from './components/use-auth';
import NotFound from './components/not-found';

function Router() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<FilterableCargo />}/>
            <Route path='registro' element={<SignupForm />}/>
            <Route path='ingresar' element={<LoginForm />}/>
            <Route path='agendar' element={<FreightRegistration />}/>
            <Route path='*' element={<NotFound />}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default Router;
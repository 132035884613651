import { useNavigate } from "react-router-dom";
import roadclosed from '../assets/road-closed.png';

export default function NotFound() {
    let navigate = useNavigate();

    return(
        <div className='flex flex-col items-center justify-center w-full min-h-screen-top p-8'>
            <img className='h-20 invert contrast-75' src={roadclosed} alt="Icono de camino cerrado"></img>
            <p className='flex justify-center my-4 w-3/4 text-gray-500'>¡Hey!, parece que la pagina que estas buscando 
            no existe.</p>
            <button className='text-sm ring-1 rounded-md px-2 py-1 ring-gray-500 hover:bg-gray-200 text-gray-700' 
            onClick={ () => navigate('/') }>Volver al inicio</button>
        </div>
    );
}
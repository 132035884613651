import { useNavigate } from 'react-router-dom';

export default function AddFreightButton() {
    let navigate = useNavigate();
    return(
        <button onClick={() => navigate('/agendar')}
        className="shadow-lg rounded-full bg-[#daff06] fixed bottom-6 right-6 p-6 text-gray-800 font-bold ">
            <span className="font-black">+ </span>
            Agendar viaje
        </button>
    );
}
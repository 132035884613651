import React, { useState} from 'react';
import capitalize from '../utils/capitalize';
import { useAuth } from './use-auth';
import trucksvg from '../assets/truck.svg';
import usersvg from '../assets/user.svg';
import crossarrows from '../assets/cross-arrows.svg';
//import defaultbg from '../assets/defaultbg.jpg';
import whatsappsvg from '../assets/whatsapp.svg';
import menusvg from '../assets/3-dot-menu.svg';
import CardDropdown from './card-dropdown';

export default function CargoCard(props) {
    const auth = useAuth();

    const [showModal, setShowModal] = useState(false);

    // Date sanitize section
    let date = new Date(props.cargo.epoch);
    let hour = date.toLocaleTimeString();

    date = date.toLocaleDateString('es-MX', { weekday: 'long', month: 'long', day: 'numeric' });
    date = capitalize(date);

    function to12HourFormat(hour) {
        let hourArray = hour.split(":");
        hourArray.pop();
        let amOrPm = hourArray[0] > 12 ? "PM" : "AM";
        hourArray[0] = ( hourArray[0] % 12 || 12 ).toString();
        hour = hourArray.join(":");
        hour = hour + amOrPm;
        return hour;
    }

    hour = to12HourFormat(hour);
    
    let backHour = undefined;
    if(props.cargo.returnEpoch) {
        backHour = new Date(props.cargo.returnEpoch);
        backHour = backHour.toLocaleTimeString();
        backHour = to12HourFormat(backHour);
    }

    // Data Section
    const origin = capitalize(props.cargo.origin);
    const destination = capitalize(props.cargo.destination);
    const roundtrip = props.cargo.roundTrip;

    // Background Selection
    //const bgGradient = 'linear-gradient(180deg, rgba(0,0,0,0.2), rgba(0,0,0,0.4))';
    let bgImage = '';
    /*if(props.cargo.bg === undefined) {
        bgImage = 'url('+ defaultbg +')';
    } else {
        bgImage = 'url('+ props.cargo.bg +')';
    }*/
    const background = bgImage;

    //let tripNumber = "*Arichi.cf/viaje/" + tripNumber + "*\n";

    // WhatsApp data
    let phone = props.cargo.phone;
    let message = "Vi tu viaje en _Tarhaul_:\n¿Tienes espacio disponible?";
    message = encodeURIComponent(message);

    return(
        <div className='CardContainer sm:w-[18rem] mx-auto w-[95%] my-1 sm:m-2 sm:border-none'>
            <div className='CardBody flex flex-col flex-nowrap justify-between rounded-2xl bg-center bg-clip-border bg-cover  
                text-gray-800 sm:h-[24rem] h-[28rem] sm:h-[28rem] font-sans sm:rounded-lg  p-8 pb-4 sm:p-8 sm:pb-4 border
                border-slate-600' style={{ backgroundImage: background }}>
                <div className='flex justify-between'>
                    <div className='DisplayUser flex flex-row w-1/2 items-center font-semibold'>
                        <img src={usersvg} alt="Icono de Usuario" className='h-[2.5rem] sm:h-[2rem]'></img>
                        <p className='sm:h-[1rem] sm:text-[1rem] h-[1.2rem] text-[1.2rem] leading-none ml-2'>{props.cargo.userName}</p>
                    </div>
                    <div className='flex relative w-6 justify-end '>
                        {auth.user && (props.cargo.ownerId === auth.user._id &&
                            <button onClick={() => setShowModal(!showModal)}>
                                <img src={menusvg} alt="Menu three-dot icon" className="w-6 sm:w-4 contrast-50" />
                            </button>
                        )}
                        {showModal && <CardDropdown setShowModal={setShowModal} _id={props.cargo._id} delete={props.delete} />}
                    </div>
                </div>
                <div className='Date flex flex-rol justify-between items-center w-full'>
                    <p className='text-[1.4rem] sm:text-[1rem]'>{date}</p>
                    <p className='font-semibold text-[1.7rem] sm:text-[1.5rem]'>{hour}</p>
                </div>
                <div className='Destination flex flex-row flex-wrap w-full'>
                    <p className="font-inter font-semibold text-[2.5rem] h-[2.5rem] sm:text-[1.6rem] sm:h-[1.6rem]
                    leading-none">
                        {origin}
                    </p>
                    <img src={trucksvg} alt="Icono de Camion" className="h-[2.7rem] mx-3 sm:mx-2 sm:h-[2rem]"></img>
                    <p className="font-inter font-semibold text-[2.5rem] h-[2.5rem] sm:text-[1.6rem] sm:h-[1.6rem]
                    leading-none">
                        {destination}
                    </p>
                </div>
                <div className='Detail h-16 sm:h-16'>
                    { roundtrip &&
                        <div className="flex flex-row justify-start items-center w-full">
                            <img src={crossarrows} alt="Icono viaje redondo" className=" mr-2 h-[1.7rem]"></img>
                            <p className='font-light text-[1.3rem]'>Regreso</p>
                            
                        </div>
                    }
                    { backHour && 
                        <div className="flex flex-row justify-start items-center w-full">
                            <p className='font-semibold text-[1.3rem]'>{backHour}</p>
                        </div>
                    }
                </div>
                <div className='Rate flex flex-col items-end'>
                    <p className='sm:text-[1rem] sm:h-[1rem] leading-none'>Desde:</p>
                    <p className='font-bold text-[3rem] h-[3rem] sm:text-[2rem] sm:h-[2rem] leading-none'>${props.cargo.rate}</p>
                </div>
                <div className='flex ring-2 ring-gray-800 bg-[#daff06] w-full rounded p-2 justify-center font-semibold'>
                    <img src={whatsappsvg} alt="Icono whatsapp" className=" mr-2 h-[1.7rem]"></img>
                    <a href={"https://wa.me/52" + phone + "?text=" + message}>Enviar WhatsApp</a>
                </div>
            </div>
        </div>
    );
}
import React from 'react';
import { Navigate } from 'react-router';
import * as yup from 'yup';
import init from '../utils/init';
import Feedback from './feedback';
import Fieldset from './fieldset';
import { authContext } from './use-auth';

// Iconography
import spinnersvg from '../assets/spinner.svg';
import originsvg from '../assets/origin.svg';
import destinationsvg from '../assets/destination.svg';
import calendarsvg from '../assets/calendar.svg';
import pricetagsvg from '../assets/pricetag.svg';
import crossarrowssvg from '../assets/cross-arrows.svg';
import clocksvg from '../assets/clock.svg';
import alertsvg from '../assets/alert.svg';

class FreightRegistration extends React.Component {
    static contextType = authContext;

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            navigate: false,
            saving: false,
            origin: '',
            destination: '',
            date: '',
            hour: '',
            rate: '',
            roundTrip: false,
            returnHour: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFeedback = this.handleFeedback.bind(this);
    }

    handleFeedback() {
        this.setState({
            error: false
        });
    }

    handleChange(event) {
        //console.log(event.target.type);

        let target = event.target.name;
        
        this.setState({
            [target]: event.target.value
        });

        this.setState({
            error: false
        });

        if(event.target.type === "checkbox") {
            this.setState({
                [target]: !this.state[target]
            })
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        
        let schema = yup.object({
            origin: yup.string()
            .required('No puedes registrar un viaje sin un origen.'),
            destination: yup.string()
            .required('No puedes registrar un viaje sin un destino.'),
            date: yup.string()
            .required('Por favor selecciona una fecha de salida.'),
            hour: yup.string()
            .required('Por favor selecciona una hora de salida.'),
            rate: yup.string()
            .required('Por favor introduce el precio minimo para este viaje.'),
        });

        let freight = this.state;
        let date = freight.date + " " + freight.hour;
        let epoch = new Date(date).getTime();
        freight.epoch = epoch;

        if(this.state.roundTrip) {
            const returnHourObject = yup.object({
                returnHour: yup.string()
                .required('Por favor selecciona una hora de regreso.'),
            });
            
            schema = schema.concat(returnHourObject);
            let returnDate = freight.date + " " + freight.returnHour;
            let returnEpoch = new Date(returnDate).getTime();
            freight.returnEpoch = returnEpoch;
        }
        
        try {
            await schema.validate(freight);
            this.setState({ saving: true });
            let myInit = init("POST");
            myInit.body = await JSON.stringify(freight);
            //console.log(myInit);
            await fetch(process.env.REACT_APP_ARICHIAPI + '/savefreight', myInit);
            this.setState({ navigate: true })
        } catch (error) {
            if(error.message === "Failed to fetch") {
                this.setState({ error: "Error de conexión, intentalo de nuevo en un momento. Cerrar", saving: false });
            } else {
                this.setState({ error: error.message, saving: false });
            }
        }
    }
    
    render() {
        let navigate = this.state.navigate;
        let error = this.state.error;
        let saving = this.state.saving;
        let roundTrip = this.state.roundTrip;
        const user = this.context.user;

        if(user === undefined) {
            return(
                <div className='flex flex-col items-center justify-center w-full min-h-screen-top p-8'>
                    <img className='h-12 grayscale contrast-0' src={alertsvg} alt="Icono de alerta"></img>
                    <p className='flex justify-center my-4 w-3/4 text-gray-500'>Debes iniciar sesión para poder registrar un viaje.
                    Has click en el boton "Ingresar".</p>
                </div>
            )
        }

        if(user === null) {
            return(
                <div className='flex w-full min-h-screen-top items-center justify-center'>
                    <img className="animate-spin h-16 brightness-75" src={spinnersvg} alt="Cargando..."></img>
                </div>
            )
        }

        return(
            <div className='flex flex-col sm:max-w-xl w-full items-center mx-auto sm:rounded-xl bg-baby-powder p-10 pb-14
            text-black min-h-screen-top'>
                <h2 className='self-start px-4 pb-1 text-4xl text-red-crayola font-black'>
                    Agendar viaje
                </h2>
                <h3 className='text-lg px-4 pb-4 self-start'>
                    Por favor danos la siguiente información sobre tu viaje.
                </h3>
                <form className='flex flex-row flex-wrap w-full' onSubmit={ this.handleSubmit }>
                    <Fieldset name="origin" label="Origen" model="text" value={ this.state.origin } placehold='De "Roswell"' 
                    icon={originsvg} onChange={ this.handleChange } />
                    <Fieldset name="destination" label="Destino" model="text" value={ this.state.destination } placehold='A "Houston"' 
                    icon={destinationsvg} onChange={ this.handleChange } />
                    <Fieldset name="date" label="Fecha de salida" model="date" value={ this.state.date } placehold="Fecha" 
                    icon={calendarsvg} onChange={ this.handleChange } />
                    <Fieldset name="hour" label="Hora de salida" model="time" value={ this.state.time } placehold= "Hora" 
                    icon={ clocksvg } onChange={ this.handleChange } />
                    <Fieldset name="rate" label="Precio Minimo" model="number" value={ this.state.rate } placehold="Precio minimo" 
                    icon={pricetagsvg} onChange={ this.handleChange } />
                    <Fieldset name="roundTrip" model="checkbox" value={ this.state.roundTrip } label="¿Regresa el mismo dia?" 
                    icon={crossarrowssvg} onChange={ this.handleChange } />

                    { roundTrip && <Fieldset name="returnHour" label="Hora de regreso" model="time" value={ this.state.backDate } 
                                    placehold="Hora de regreso" icon={clocksvg} onChange={ this.handleChange } />
                    }
                </form>

                { error && <Feedback styling="flex fixed bottom-0 z-[1] bg-gray-700 w-full text-white text-sm h-14 items-center justify-center 
                               px-4" message={error} onClick={this.handleFeedback} />
                }

                <button type='submit' onClick={this.handleSubmit} className='fixed bottom-0 w-full bg-red-crayola py-4 text-white font-semibold font-inter'>
                    { saving ? (
                        <div className='flex flex-row justify-center items-center'>
                            <img className='h-5 mr-1 animate-spin' src={spinnersvg} alt="Boton guardar"></img>
                            <p>Guardando</p>
                        </div> )
                        : 'Guardar'
                    }
                </button>

                { navigate && <Navigate to='/' /> }
            </div>
        );
    }
}

export default FreightRegistration;
export default function Fieldset(props) {
    let type = props.model;
    let label = props.label;

    switch (props.model) {
        case "checkbox":
            return(
                <fieldset className="flex flex-row shrink-0 grow items-center bg-gray-100 ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-rich-black
                rounded-xl p-4 mb-4 mx-1">
                    <input type="checkbox" name={ props.name } checked={props.value} onChange={ props.onChange } className="h-6 w-6"></input>
                    <label className="h-6 ml-2 text-rick-black font-semibold">{props.label}</label>
                </fieldset>
            )
        default:
            break;
    }

    return(
        <fieldset className='flex flex-col flex-1 w-80 bg-gray-100 ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-rich-black
        rounded-xl mb-4 mx-1 px-4 pb-4'>
            { label ? <label className='flex text-xs h-2 mt-2 ml-8  items-center'>
                { label }
            </label> : <div className="h-4"></div>
            }
            <div className="flex flex-row">
                <img className='brightness-50 w-6' src={ props.icon } alt="Icon"></img>
                <input type={ type } name={ props.name } className='w-56 flex-1 bg-transparent placeholder-gray-400 ml-2
                outline-none text-black font-semibold' value={ props.value } placeholder={ props.placehold } onChange={ props.onChange }>
                </input>
            </div>
        </fieldset>
    )
}
import alertsvg from '../assets/alert.svg';

function Feedback(props) {
    return(
        <button className={"Feedback " + props.styling} onClick={props.onClick}>
            <img className='hue-rotate-[333deg] h-[1.2rem] mr-1' src={alertsvg} alt="Alert Icon"></img>
            {props.message}
        </button>
    );
};

export default Feedback;
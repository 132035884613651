import init from '../utils/init';
import trashcan from '../assets/trashcan.svg';

export default function CardDropdown(props) {
  async function deleteFreight() {
    if(window.confirm("El viaje se eliminará definitivamente, ¿Estás seguro?")){
      props.setShowModal(false);

      try {
        // First delete the item form the db
        let myInit = init("POST");
        myInit.body = await JSON.stringify({ _id: props._id});
        await fetch(process.env.REACT_APP_ARICHIAPI + '/remove-freight', myInit);
        // Next we delete the react item from the UI
        props.delete();
      } catch(error) {
        alert("Lo sentimos, hubo un error al eliminar el viaje, por favor reintentalo.");
      }

    } else {
      props.setShowModal(false);
    }
  }  

  return(
    <>
      <div onClick={() => props.setShowModal(false)} className="Shadow fixed top-0 left-0 z-[51] bg-black/30 w-full h-full"></div>
      <div className="Dropdown flex flex-wrap absolute z-[52] top-8 right-[-1.2rem] bg-white rounded-xl py-4 
      shadow-md font-semibold items-center justify-center">
        <button onClick={deleteFreight} className="flex items-center hover:bg-gray-200 shrink-0 w-28">
          <img src={trashcan}alt="Icono de basura" className="h-4 ml-4 mr-1"></img>
          <span className='mr-4'>Eliminar</span>
        </button>
      </div>
    </>
)}
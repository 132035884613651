import React, { useEffect, useState } from 'react';
import { useAuth } from './use-auth';
import CargoCard from './cargo-card';
import init from '../utils/init';
import alertsvg from '../assets/alert.svg';
import spinnersvg from '../assets/spinner.svg';
import AddFreightButton from './add-freight-button';

export default function CargoList(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [freightsArray, setFreightsArray] = useState();
    const [fetchError, setFetchError] = useState(null);

    const auth = useAuth();

    useEffect( () => {
        //if(isLoading) {
            async function fetchData() {
                try {
                    const response = await fetch(process.env.REACT_APP_ARICHIAPI + '/freights/?' + props.filter, init("GET"));
                    const freightsData = await response.json();
                    setFreightsArray(freightsData);
                    setIsLoading(false);
                } catch (error) {
                    error.message = null;
                    setFetchError("Ocurrio un problema al cargar los datos. Puede que no tengas conexión a internet o hay un problema con nuestros servidores.");
                    setIsLoading(false);
                }
            }
            fetchData();
        //} 
    }, [isLoading, props.filter]);

    useEffect(reload, [props.forceSearch]);

    function reload() {
        setIsLoading(true);
        setFetchError(false);
    }

    if(isLoading) {
        return(
            <div className='flex w-full min-h-screen-top items-center justify-center'>
                <img className="animate-spin h-16 brightness-75" src={spinnersvg} alt="Cargando..."></img>
            </div>
        );
    }

    if(fetchError) {
        return(
            <div className='flex flex-col items-center justify-center w-full min-h-screen-top p-8'>
                <img className='h-12 grayscale contrast-0' src={alertsvg} alt="Icono de alerta"></img>
                <p className='flex justify-center my-4 w-3/4 text-gray-500'>{fetchError}</p>
                <button className='text-sm ring-1 rounded-md px-2 py-1 ring-gray-500 hover:bg-gray-200 text-gray-700' 
                onClick={reload}>Reintentar</button>
            </div>
        );
    }

    function handleDelete(id) {
        const filteredFreights = freightsArray.filter((item) => item._id !== id);
        setFreightsArray(filteredFreights);
    }
    
    return(
        <div className="CargoList flex flex-col min-h-full min-w-full sm:px-8">
            <div className='CardDeck flex flex-wrap justify-start'>
                {freightsArray.map((freight) => 
                    <CargoCard key={freight._id} cargo={freight} delete={() => handleDelete(freight._id)}/>
                )}
                <div className="flex justify-center text-[4rem] w-full leading-none sm:hidden text-gray-300 h-[1rem] mb-10 
                items-center">
                    .
                </div>
            </div>
            { auth.user && <AddFreightButton />}
        </div>
    );
}
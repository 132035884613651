import CargoList from './cargo-list';
import searchIcon from '../assets/search.svg';
import closeIcon from '../assets/close.svg';
import filterIcon from '../assets/filter.svg';
import { useState } from 'react';
import { useSearchParams } from "react-router-dom";

export default function FilterableCargo() {
  const [filter, setFilter] = useState("freights");
  const [search, setSearch] = useState("");
  let [searchParams] = useSearchParams();
  const [forceSearch, setForceSearch] = useState(false);

  function handleSelect(event) {
    const target = event.target;
    setFilter(target.value);
    searchParams.set("filter", target.value);
  }

  function resetSelect(event) {
    setFilter("freights");
    searchParams.delete("filter");
  }

  function handleSearch(event) {
    const value = event.target.value;
    if(value !== "") {
      searchParams.set("search", value);
      setSearch(value);
    } else {
      searchParams.delete("search");
      setSearch(value);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setForceSearch(!forceSearch);
  }

  return(
    <>
      <h2 className="Title md:text-4xl sm:px-8 text-3xl font-bold text-gray-800 mx-4 sm:mx-2 my-4">Transportes Disponibles</h2>

      <form className='flex justify-center items-center sm:justify-start sm:w-1/2 mx-4 sm:mx-8 mb-4 ring-1 ring-slate-300
      rounded-full focus-within:bg-slate-200 focus-within:ring-2'>
        <div className='flex shrink-0 items-center focus-within:bg-white rounded-full px-2'>
          <div className='bg-slate-600 p-1 rounded-full w-4 h-4'>
            { filter === "freights" &&  <img src={filterIcon} alt="Icono de filtro" className='w-3 invert'/>}
            { filter !== "freights" &&  <img src={closeIcon} alt="Cerrar" onClick={resetSelect} className='w-4 invert'/>}
          </div>
          <select value={filter} onChange={handleSelect} className='flex appearance-none focus:outline-none bg-transparent
          text-gray-600 h-12 font-semibold text-center w-14'>
            <option hidden value="freights">Filtros</option>
            <option value="from">Origen</option>
            <option value="to">Destino</option>
          </select>
        </div>
        <div className='h-8 border-l'></div>
        <div className='flex flex-1 focus-within:bg-white rounded-full'>
        <input type="text" value={search} onChange={handleSearch} placeholder="Buscar" className="flex flex-1 w-12 pl-2 font-semibold
        focus:outline-none bg-transparent h-12"></input>
        <button onClick={handleSubmit} className="flex shrink-0 rounded-full bg-red-crayola
        h-12 w-28 justify-center items-center">
          <img src={searchIcon} alt="Icono de busqueda" className='h-4 invert'/>
        </button>
        </div>
      </form>
      <CargoList filter={searchParams.toString()} forceSearch={forceSearch} />
    </>
  );
}
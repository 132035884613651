import React from 'react';
import { Navigate } from 'react-router';
import { authContext } from './use-auth';
import * as yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import Feedback from './feedback';
import usersvg from '../assets/user.svg';
import mailsvg from '../assets/mail.svg';
import passwordsvg from '../assets/password.svg';
import spinnersvg from '../assets/spinner.svg';
import telephonesvg from '../assets/telephone.svg';
import Fieldset from './fieldset';
import CustomError from '../utils/CustomError';

class SignupForm extends React.Component {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();

        this.state = {
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            phone: '',
            error: false,
            navigate: false,
            saving: false,
            captcha: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.captchaSolved = this.captchaSolved.bind(this);
        this.captchaExpired = this.captchaExpired.bind(this);
    }

    static contextType = authContext;

    handleChange(event) {
        //console.log(event.target.name);
        const targetName = event.target.name;

        if(targetName === "username") {
            event.target.value = event.target.value.toLowerCase();
        }

        this.setState({
            [targetName]: event.target.value,
            error: false,
        });

        if(this.state.captcha !== '' && this.state.error) {
            this.recaptchaRef.current.reset();
            //console.log("reseteao");
        }
    }

    captchaSolved(token) {
        //console.log(token.length);
        this.setState({ captcha: token});
    }

    captchaExpired() {
        this.setState({ captcha: '', error: "El captcha expiro por favor resuelvelo de nuevo." });
    }

    async handleSubmit(event) {
        event.preventDefault();
        
        let auth = this.context;
        let schema = yup.object({
            username: yup.string()
                .required('Debes escribir un nombre de usuario.')
                .max(30,"El nombre de usuario es demasiado largo.")
                .matches(/^[a-z]+(\.?[0-9,a-z])*$/,"El nombre de usuario debe comenzar con una letra y solo puede contener letras, numeros y puntos. No debe haber dos o mas puntos seguidos. Debe terminar con letra o numero."),
            email: yup.string()
                .email("Escribe un email valido.")
                .required('Por favor escribe tu email.'),
            phone: yup.string()
                .matches(/^\d{10}$/,"Asegurate de que el telefono tenga 10 digitos ej. (xxx)-xxx-xx-xx"),
            password: yup.string()
                .required('Debes escribir una contraseña en el campo "Contraseña".')
                .max(64, 'La contraseña es demasiado larga.'),
            passwordConfirmation: yup.string()
                .required('Escribe de nuevo tu contraseña en el campo "Confirmar contraseña".'),
            captcha: yup.string()
                .required('Debes llenar la casilla "No soy un robot".'),
        });
        
        try {
            if(this.state.password !== this.state.passwordConfirmation){
                throw new CustomError("La contraseña y confirmación de contraseña deben ser iguales.")
            }
            await schema.validate(this.state);
            this.setState({ saving: true });
            await auth.signup(this.state);
            this.setState({ navigate: true });

        } catch (error) {
            if(error.message === "Failed to fetch") {
                this.setState({ error: "Error de red, intenta de nuevo mas tarde.", saving: false });
            } else {
                this.setState({ error: error.message, saving: false });
            }
        }
    }
    
    render() {
        let navigate = this.state.navigate;
        let error = this.state.error;
        let saving = this.state.saving;

        return(
            <div className='flex flex-col font-sans font-inter sm:max-w-xl w-full items-center mx-auto sm:rounded-xl bg-baby-powder p-10
            text-black mt-4 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.4)]'>
                <h2 className='self-start px-4 pb-1 text-5xl text-red-crayola font-black'>
                    Registrate!
                </h2>
                <h3 className='text-xl px-4 pb-4'>
                    Crea una cuenta para utilizar la plataforma, comienza con el plan Free*.
                </h3>
                <form className='flex flex-row flex-wrap w-full' onSubmit={this.handleSubmit}>
                    <Fieldset name="username" model="text" placehold="userejemplo1" icon={usersvg} label="Nombre de usuario" 
                    onChange={this.handleChange} />
                    <Fieldset name="phone" model="phone" placehold="Telefono" icon={telephonesvg} label="Teléfono" 
                    onChange={this.handleChange} />
                    <Fieldset name="email" model="email" placehold="Correo electrónico" icon={mailsvg} label="Correo electronico" 
                    onChange={this.handleChange} />
                    <Fieldset name="password" model="password" icon={passwordsvg} label="Contraseña" 
                    placehold="Contraseña" onChange={this.handleChange}/>
                    <Fieldset name="passwordConfirmation" model="password" icon={passwordsvg} label="Confirmar contraseña" 
                    placehold="Contraseña" onChange={this.handleChange}/>
                    <fieldset className='flex items-center mb-4'>
                        <ReCAPTCHA className='captcha' ref={this.recaptchaRef} sitekey="6Lc-wvIhAAAAABX39GSATOFnqav1uuKbtK5FRV5O"
                        onChange={this.captchaSolved} onExpired={this.captchaExpired} />
                    </fieldset>

                    <div className='flex min-h-4 w-full items-center'>
                    { error && <Feedback styling="flex flex-1 text-red-crayola text-sm rounded bg-white min-h-4 items-center justify-center 
                        px-4 mb-4" message={error} />
                    }
                    </div>
                    
                    <button type='submit' className='block w-full rounded-xl bg-red-crayola 
                        py-3 text-white font-semibold font-inter'>
                        { saving ? (
                            <div className='flex flex-row justify-center items-center'>
                                <img className='h-5 mr-1 animate-spin' src={spinnersvg} alt="Cargando..."></img>
                                <p>Guardando</p>
                            </div> )
                            : 'Crear cuenta' 
                        }
                    </button>
                </form>
                { navigate && <Navigate to='/' /> }
            </div>
        );
    }
}

export default SignupForm;
import { Outlet } from "react-router-dom";
import TopNavBar from "./top-nav-bar";

export default function Layout() {
    return(
        <>
            <TopNavBar/>
            <Outlet/>
        </>
    );
}
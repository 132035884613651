import React from 'react';
import { useAuth } from './use-auth';
import { useNavigate } from 'react-router-dom';
import tarhaullogo from '../assets/tarhaul_logo.svg';
import usersvg from '../assets/user.svg';
import tarhaul_badge from '../assets/tarhaul_badge.png';
import bell from '../assets/bell.svg';

export default function TopNavBar(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    function handleNotification() {
        Notification.requestPermission().then(function(result) {
            if(result === 'granted') {
                navigator.serviceWorker.ready.then(function(registration) {
                    registration.showNotification("Notificaciones Activadas", { 
                        body: "Gracias!, ahora podremos mantenerte al dia sobre tus viajes e información importante.", 
                        badge: tarhaul_badge, icon: "none"
                    });
                })
            }
        });
    }
    
    return(
        <header className='sticky top-0 flex-none z-40 bg-white/30 backdrop-blur min-w-full h-16 border-b px-8'>
            <div className='flex flex-row justify-between items-center h-16'>
                <img src={tarhaullogo} onClick={ () => navigate('/') } alt='Tarhaul logo' className='h-10'></img>
                <div className='flex flex-row items-center'>
                <img src={bell} className="h-6 pr-3" onClick={ () => handleNotification() } alt="Activar Notificaciones"></img>
                { auth.user === undefined && (
                    <button className='flex flex-row rounded-md p-2 px-2 h-[2rem] ring-2 ring-gray-800 items-center 
                        hover:bg-gray-100' onClick={ () => navigate('/ingresar') }
                    >
                        <img src={usersvg} alt="Icono de Usuario" className='h-[1.2rem] brightness-[.20]'></img>
                        <p className='text-gray-800 font-semibold text-[1rem] h-[1rem] ml-1 leading-none'>Ingresar</p>
                    </button>
                )}
                { auth.user && (
                    <div className='flex flex-row items-center ring-2 rounded-lg p-1 ring-gray-800'>
                        <img src={usersvg} alt="Icono de Usuario" className='h-[1.2rem] brightness-0 mr-1'></img>
                        <span className='mr-2 text-gray-800 text-[.8rem] h-[.8rem] leading-none'>{auth.user.username}</span>
                        <button className='flex flex-row p-1 border-l-2 border-gray-800 items-center'
                        onClick={ () => auth.signout() }>
                            <p className='text-gray-800 text-[.8rem] h-[.8rem] leading-none'>Salir</p>
                        </button>
                    </div>
                )}
                </div>
            </div>
        </header>
    );
}
import React from 'react';
import { Navigate } from 'react-router';
import { authContext } from './use-auth';
import * as yup from 'yup';
import Feedback from './feedback';
import mailsvg from '../assets/mail.svg';
import passwordsvg from '../assets/password.svg';
import spinnersvg from '../assets/spinner.svg';
import usersvg from '../assets/user.svg';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: false,
            navigate: false,
            saving: false,
            toRegister: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goToRegister = this.goToRegister.bind(this);
    }

    static contextType = authContext;

    handleChange(event) {
        //console.log(event);

        let target = event.target.className;
        target = target.split(" ")[0];
        
        
        this.setState({
            [target]: event.target.value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        
        let auth = this.context;
        let schema = yup.object({
            email: yup.string()
                .email("Escribe un email valido.")
                .required('Por favor escribe tu email.'),
            password: yup.string()
                .required('Debes escribir una contraseña.')
                .max(64, 'La contraseña es demasiado larga.'),
        });
        
        try {
            await schema.validate(this.state);
            this.setState({ saving: true });
            await auth.signin(this.state);
            this.setState({ navigate: true })
        } catch (error) {
            if(error.message === "Failed to fetch") {
                this.setState({ error: "Error de red, intenta de nuevo mas tarde.", saving: false });
            } else {
                //console.log(error.message);
                this.setState({ error: error.message, saving: false }); 
            }
        }
    }

    goToRegister() {
        this.setState({ toRegister: true });
    }
    
    render() {
        let navigate = this.state.navigate;
        let toRegister = this.state.toRegister;
        let error = this.state.error;
        let saving = this.state.saving;

        return(
            <div className='font-sans font-inter flex flex-col sm:max-w-xl w-full items-center mx-auto sm:rounded-xl bg-baby-powder p-10
            text-black mt-4 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.4)]'>
                <h2 className='self-start px-4 mb-4 text-5xl text-red-crayola font-black'>
                    Iniciar sesión
                </h2>
                <form className='w-full' onSubmit={this.handleSubmit}>
                    <fieldset className='flex items-center bg-gray-100 ring-1 ring-gray-300 focus-within:ring-2 rounded-xl mb-4'>
                        <label className='w-6 ml-4 my-4'>
                            <img className='brightness-50' src={mailsvg} alt="Mail Icon"></img>
                        </label>
                        <input type='email' className='email w-28 flex-1 p-4 bg-transparent 
                            placeholder-gray-400 outline-none text-black font-semibold' value={this.state.value}
                            placeholder='Correo electronico'  onChange={this.handleChange}>
                        </input>
                    </fieldset>
                    <fieldset className='flex items-center bg-gray-100 ring-1 ring-gray-300 focus-within:ring-2 rounded-xl mb-4'>
                        <label className='w-6 ml-4 my-4'>
                            <img className='brightness-50' src={passwordsvg} alt="Mail Icon"></img>
                        </label>
                        <input type='password' className='password w-28 flex-1 p-4 bg-transparent 
                            placeholder-gray-400 outline-none text-black font-semibold' value={this.state.value}
                            placeholder='Contraseña' onChange={this.handleChange}>
                        </input>
                    </fieldset>
                    <div className='flex h-4 items-center'>
                    { error && <Feedback styling="flex flex-1 text-red-crayola text-sm rounded bg-white h-4 items-center justify-center 
                        px-4 mb-4" message={error} />
                    }
                    </div>
                    <button type='submit' className='block w-full rounded-xl bg-red-crayola 
                        py-3 text-white font-semibold font-inter'>
                        { saving ? (
                            <div className='flex flex-row justify-center items-center'>
                                <img className='h-5 mr-1 animate-spin' src={spinnersvg} alt="Cargando..."></img>
                                <p>Comprobando</p>
                            </div> )
                            : 'Ingresar' 
                        }
                    </button>
                </form>
                <p className='my-2'>O si aun no tienes una cuenta</p>
                <button className='flex flex-row w-full justify-center rounded-md p-2 px-4 h-[2rem] bg-gray-800 items-center'
                    onClick={ this.goToRegister }>
                        <img src={usersvg} alt="Icono de Usuario" className='h-[1.2rem]'></img>
                        <p className='text-white text-[1rem] h-[1rem] ml-1 leading-none'>Registrarte</p>
                </button>
                { navigate && <Navigate to='/' /> }
                { toRegister && <Navigate to='/registro' />}
            </div>
        );
    }
}

export default LoginForm;
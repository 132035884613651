import React, { useContext, createContext, useState, useEffect } from 'react';
import CustomError  from '../utils/CustomError';
import init from '../utils/init';


export const authContext = createContext();

// This is a React Component
export function ProvideAuth(props) { // Here the 'value' assign the context
    const auth = useProvideAuth();   // Here we populate the context value with all the content 
                                     // of the useProvideAuth function 
    return <authContext.Provider value={auth}>{ props.children }</authContext.Provider>
}

// This is a custom Hook for all Components who want to use authentication
export const useAuth = () => {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null);

    useEffect( () => {
        async function fetchUser() {
            if(user === null) {
                try {
                    let response = await fetch(process.env.REACT_APP_ARICHIAPI + '/me', init());
                    if(response.ok) { // What to do if the fetch request is successful
                        let body = await response.json();
                        setUser(body);
                    } else {
                        setUser(undefined);
                    }
                } catch (error) {
                    setUser(undefined);
                }
            }
        }

        fetchUser();
    }, [user]);

    const signup = async (state) => {
        let init = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json' },
            body: JSON.stringify(state),
        };
        let response = await fetch(process.env.REACT_APP_ARICHIAPI + '/register', init);

        if(response.status === 201) {
            response = await response.json();
            setUser(null);
        } else {
            response = await response.json();
            throw new CustomError(response.description);
        }
        
        //return signupStatus;
    }

    const signin = async (state) => {
        let init = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json' },
            body: JSON.stringify(state),
        };
        let response = await fetch(process.env.REACT_APP_ARICHIAPI + '/login', init);

        if(response.status === 201) {
            response = await response.json();
            setUser(null);
        } else if(response.status === 400) {
            response = await response.json();
            throw new CustomError("Correo o contraseña incorrectos.");
        } else {
            response = await response.json();
            throw new CustomError(response.error);
        }
    }

    const signout = async () => {
        let init = {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json' },
            };

        fetch(process.env.REACT_APP_ARICHIAPI + '/logout', init)
        .then( (res) => {
            if(res.status === 205 ) {
                setUser(null);
            };
        }).catch( (e) => e );   
    };

    return {
        user,
        signup,
        signin,
        signout,
    }
}